import React from "react";
import { Bold, SectionWrapper, Text } from "./Footer.styles";

function Footer() {
  return (
    <SectionWrapper>
      <Text href="https://vodamedia.qa/">
        Copyright 2022 © <Bold>Cosmedic Center</Bold>
      </Text>
      <Text>Powered by <a href="https://novalabs-qa.com" target="_blank" rel="noreferrer" style={{ color: "#838383" }}>Nova Labs</a></Text>
    </SectionWrapper>
  );
}

export default Footer;
