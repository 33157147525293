import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { scaleHeight, scaleWidth } from "../../styles/mixins";
ReactModal.setAppElement("#root");

function Modal({ modalIsOpen, closeModal }) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "50%",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  return (
    <div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'>
        <H2>Terms & Conditions </H2>
        <br />

        <UL>
          <li>
            The 20% off is valid on all our services. Except Plastic surgeries
          </li>
          <li>
            Offer is valid for a limited period of time and can be renewed after
            one year.
          </li>
          <li>
            The offer cannot be used in conjunction with any other promotion,
            offer or discount.
          </li>
          <br />
          <p>
            By using this offer, you agree to bound by these terms & condition.
          </p>
        </UL>
        <br />

        <CloseButton onClick={closeModal}>X</CloseButton>
      </ReactModal>
    </div>
  );
}

export default Modal;

const UL = styled.ul`
  font-family: var(--font-family);
  font-size: ${scaleWidth(12)}vw;
  font-weight: normal;
  color: "Black";
  margin-bottom: ${scaleHeight(5)}vh;
  line-height: 21px;

  @media screen and (max-width: 1280px) {
    font-size: 18px;
    > li {
      font-size: 18px;
      line-height: 21px;
    }
    > p {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 962px) {
    /* font-size: ${scaleWidth(16, 550)}vw; */
    font-size: 16px;
    > li {
      font-size: 16px;
      line-height: 21px;
    }
    > p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 550px) {
    font-size: 14px;
    > li {
      font-size: 14px;
      line-height: 21px;
    }
    > p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 653px) {
    font-size: 14px;
    > li {
      font-size: 12px;
      line-height: 21px;
    }
    > p {
      font-size: 12px;
    }
  }
`;
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border: none;
  background: transparent;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  &:hover {
    color: #f00;
  }
`;
const H2 = styled.h2`
  @media screen and (max-width: 670px) {
    font-size: 19px;
  }
`;
