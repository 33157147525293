import styled from 'styled-components';
import { scaleHeight, scaleWidth } from '../../styles/mixins';

const SectionWrapper = styled.section`
  background-color: var(--color-footer);
  display: flex;
  justify-content: center;
  padding: ${scaleHeight(10)}vh 0;

  > a > img {
    width: ${scaleWidth(200)}vw;
    /* height: ${scaleHeight(171)}vh; */

    @media screen and (max-width: 1280px) {
      width: ${scaleWidth(200, 1280)}vw;
    }

    @media screen and (max-width: 962px) {
      width: ${scaleWidth(200, 962)}vw;
    }

    @media screen and (max-width: 550px) {
      width: ${scaleWidth(200, 550)}vw;
    }
  }
`;

export { SectionWrapper };
