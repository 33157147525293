import './App.css';
import { Header, Footer, Form2 } from './containers';

function App() {
  return (
    <div className='App'>
      <Header />
      {/* <Form /> */}
      <Form2 />
      <Footer />
    </div>
  );
}

export default App;
