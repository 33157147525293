import styled from "styled-components";
import { scaleHeight } from "../../styles/mixins";

const SectionWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex: 1;

  padding: 5%;

  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  @media screen and (max-width: 1536px) {
    /* width: 20rem; */
  }

  @media screen and (max-width: 950px) {
    flex-direction: column;
    max-width: 550px;
  }

  @media screen and (max-width: 600px) {
    /* flex-direction: column; */
  }
`;

const DescriptionDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.75;
  /* background-color: blue; */
  margin-right: 5%;

  > h1 {
    font-family: var(--font-family);
    font-size: 200%;
    font-weight: bold;
    color: #e4b69c;
    margin-bottom: 5%;
  }

  > p {
    font-family: var(--font-family);
    font-size: 125%;
    color: #e4b69c;
  }

  > p:last-of-type {
    font-size: 120%;
    margin-top: 4%;
    font-style: italic;
    color: white;
  }

  @media screen and (max-width: 1200px) {
    /* flex: 1; */
    > h1 {
      font-size: 375%;
    }

    > p {
      font-size: 115%;
    }
  }

  @media screen and (max-width: 950px) {
    flex: 1;
    margin-bottom: 10%;
    > h1 {
      font-size: 290%;
    }

    > p {
      font-size: 120%;
    }
  }
  @media screen and (max-width: 768px) {
    flex: 1;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  border-radius: 10px;
  padding: 3%;
  width: 90%;

  > p {
    color: red;
    font-family: var(--font-family);
    font-size: 100%;
    margin-bottom: 3%;
  }

  > label {
    font-family: var(--font-family);
    font-size: 100%;
    font-weight: bold;
    color: "black";
    margin-bottom: 1%;
  }
  > input {
    height: 2.5rem;

    margin-bottom: 2%;

    border-radius: 0px;
    border-width: 1px;

    font-family: var(--font-family);
    font-size: 100%;

    padding: 2%;
  }
  > input:focus {
    outline-color: #e4b69c;
  }

  > button {
    height: 2.5rem;
    border-radius: 0px;
    border: 0px;
    background-color: #e4b69c;
    color: var(--color-footer);
    font-size: 100%;

    margin-top: 10%;
    cursor: pointer;

    &:hover {
      opacity: 0.85;
    }

    /* opacity: ${props => (props.touched ? 0.5 : 1)};
    transition: "opacity 300ms ease"; */
  }

  @media screen and (max-width: 950px) {
    padding: 10%;
  }

  @media screen and (max-width: 600px) {
    padding: 10% 5%;

    > label {
      font-family: var(--font-family);
      font-size: 110%;
      font-weight: bold;
      color: "black";
      margin-bottom: 1%;
    }
    > input {
      height: 3rem;
      margin-bottom: 2%;
      font-size: 100%;
    }

    > button {
      height: 3rem;
      margin-top: 5%;
    }
  }
`;

const DownloadForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
  padding: 3%;

  > h2 {
    font-family: var(--font-family);
    font-size: 200%;
    font-weight: bold;
    color: black;
    margin-bottom: ${scaleHeight(25)}vh;
  }

  > p {
    font-family: var(--font-family);
    font-size: 100%;
    color: black;
    margin-bottom: ${scaleHeight(50)}vh;
  }

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${scaleHeight(20)}vh;
  }

  > a:last-of-type {
    margin-bottom: 0;
  }

  > a > img {
    width: 60%;
  }

  @media screen and (max-width: 950px) {
    padding: 10%;
  }

  @media screen and (max-width: 600px) {
    padding: 10% 5%;
  }
`;

const DownloadLink = styled.a`
  opacity: ${props => (props.isDisabled ? 0.7 : 1)};
`;
const TermsAndCondition = styled.button`
  border: none;
  background: none;
  color: blue;
  text-decoration: underline;
`;

export {
  SectionWrapper,
  DescriptionDiv,
  Form,
  DownloadForm,
  DownloadLink,
  TermsAndCondition,
};
