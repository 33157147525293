import styled from "styled-components";
import { scaleHeight, scaleWidth } from "../../styles/mixins";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${scaleHeight(20)}vh;

  > input:focus {
    outline-color: #e4b69c;
  }
`;

const Label = styled.label`
  font-family: var(--font-family);
  font-size: ${scaleWidth(12)}vw;
  font-weight: bold;
  color: "Black";
  margin-bottom: ${scaleHeight(5)}vh;

  @media screen and (max-width: 1280px) {
    font-size: 18px;
  }

  @media screen and (max-width: 962px) {
    /* font-size: ${scaleWidth(16, 550)}vw; */
    font-size: 16px;
  }

  @media screen and (max-width: 550px) {
    font-size: 14px;
  }
`;

const Input = styled.input`
  display: flex;
  height: ${scaleHeight(46)}vh;
  padding: ${scaleWidth(10)}vw;
  border-radius: 0px;
  border-width: 1px;

  font-family: var(--font-family);
  font-size: ${scaleWidth(14)}vw;
  /* outline: none; */
  /* border: 0.5px solid black; */

  @media screen and (max-width: 1280px) {
  }

  @media screen and (max-width: 962px) {
    /* font-size: ${scaleWidth(20, 550)}vw; */
    font-size: 20px;
  }

  @media screen and (max-width: 550px) {
    height: ${scaleHeight(60)}vh;
    font-size: 16px;
  }
`;

export { Wrapper, Input, Label };
