import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import {
  SectionWrapper,
  DescriptionDiv,
  Form,
  DownloadForm,
  DownloadLink,
  TermsAndCondition,
} from "./Form2.styles";

import addAppleWallet from "../../assets/add-to-apple-wallet-logo.svg";
import addGoogleWallet from "../../assets/Add_to_Google_Wallet_badge.svg.png";

import { useRef } from "react";
import { object, string } from "yup";
import axiosInstance from "../../utils/axiosInstance";
import { getMobileOperatingSystem, isWebview } from "../../utils/helper";
import { DOMAIN } from "../../config";
import { Spinner } from "../../components";
import Modal from "../../components/modal/Modal";

function Form2() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [serialId, setSerialId] = useState(null);
  const [qId, setQId] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [googleURL, setGoogleURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function handleCheckboxChange(event) {
    setIsChecked(event.target.checked);
  }

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }
  const fnameRef = useRef();
  const lnameRef = useRef();
  const mobileRef = useRef();
  const qidRef = useRef();

  const OS = getMobileOperatingSystem();
  const { isSC } = isWebview();

  const getGooglePassUrl = useCallback(async () => {
    if ((OS === "Android" || OS === "unknown") && qId && serialId) {
      try {
        const response = await axiosInstance.get(
          `/google/wallet-pass?qId=${qId}&serialId=${serialId}`
        );

        const data = response.data;

        setGoogleURL(data.data.passURL);
        setIsLoading(false);
      } catch (error) {
        setGoogleURL(null);
        setErrorMsg(error.message || "Something went wrong! Please try again.");
        return;
      }
    }
  }, [OS, qId, serialId]);

  useEffect(() => {
    getGooglePassUrl();
  }, [getGooglePassUrl]);

  const userSchema = object({
    firstName: string()
      .min(2, "First Name must be at least 2 characters")
      .max(100, "First Name must be at least 100 characters")
      .required("First Name is Required"),
    lastName: string("")
      .min(2, "Last Name must be at least 2 characters")
      .max(100, "Last Name must be at least 100 characters")
      .required("Last Name is Required"),
    mobile: string()
      .min(8, "Mobile must have 8 digits")
      .max(8, "Mobile must have 8 digits")
      .matches(/^[0-9]*$/, "Mobile is not in correct format")
      .required("Mobile is Required"),
    qId: string()
      .min(11, "QID must have 11 digits")
      .max(11, "QID must have 11 digits")
      .matches(/^[0-9]*$/, "QID is not in correct format")
      .required("QID is Required"),
  });

  const submit = async () => {
    const user = {
      firstName: fnameRef.current.value,
      lastName: lnameRef.current.value,
      qId: qidRef.current.value,
      mobile: mobileRef.current.value,
    };

    try {
      await userSchema.validate(user);
    } catch (err) {
      setErrorMsg(err.errors[0]);
      return;
    }

    try {
      const response = await axiosInstance.post("/users/add", user);
      const data = await response.data;
      const userDto = data.userDto;

      setQId(userDto.qId);
      setSerialId(userDto.serialId);

      setIsSubmitted(true);
      if (OS === "Android" || OS === "unknown") {
        setIsLoading(true);
      }
    } catch (error) {
      setErrorMsg(error.message || "Something went wrong! Please try again.");
    }
  };

  const Input = ({ id, label, innerRef }) => (
    <>
      <label htmlFor={id}>{label}</label>
      <input ref={innerRef} type='text' id={id} name={id} />
    </>
  );

  const Button = ({ image, link, isDisabled = false }) => (
    <DownloadLink
      href={link}
      download
      isDisabled={isDisabled}
      onClick={e => {
        if (isDisabled) e.preventDefault();
      }}>
      <img src={image} alt={`add_to_${OS}_wallet`} />
    </DownloadLink>
  );

  const DownloadButtons = () => {
    const appleWalletURL = `${DOMAIN}/apple/wallet-pass?qId=${qId}&serialId=${serialId}`;

    switch (OS) {
      case "iOS":
        return (
          <Button
            image={addAppleWallet}
            link={appleWalletURL}
            isDisabled={isSC}
          />
        );

      case "Android":
        return !isLoading ? (
          <Button image={addGoogleWallet} link={googleURL} />
        ) : (
          <Spinner />
        );

      default:
        return !isLoading ? (
          <>
            <Button
              image={addAppleWallet}
              link={appleWalletURL}
              isDisabled={isSC}
            />
            <Button image={addGoogleWallet} link={googleURL} />
          </>
        ) : (
          <Spinner />
        );
    }
  };

  const renderWebViewMessage = () => {
    return (
      <small className='danger mt-1'>
        <i>
          {isSC
            ? "Try to use the standard browser for the better performance (Chrome/Safari)"
            : ""}
        </i>
      </small>
    );
  };

  return (
    <SectionWrapper>
      <DescriptionDiv>
        {/* <p>Welcome to Cosmedic Center Family Card.</p> */}

        <p>
          In Cosmedic Center, we believe that beauty has no limits or
          classification.
        </p>
        <br />
        <br />

        <p>
          We're proud to offer our beloved family and friends access to
          exclusive discounts of up to 20% on every treatment.
        </p>

        {/* <p>Thank you for choosing us!</p> */}

        <p>Note: Plastic surgeries are not included.</p>
      </DescriptionDiv>
      {!isSubmitted ? (
        <Form>
          {errorMsg !== "" && <p>** {errorMsg} **</p>}
          <Input label={"First Name"} innerRef={fnameRef} id='fname' />
          <Input label={"Last Name"} innerRef={lnameRef} id='lname' />
          <Input label={"Mobile"} innerRef={mobileRef} id='mobile' />
          <Input label={"QID"} innerRef={qidRef} id='qid' />
          <span>
            <input
              type='checkbox'
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            &nbsp; I agree to the{" "}
            <TermsAndCondition onClick={openModal}>
              terms and conditions
            </TermsAndCondition>
          </span>
          <button
            type='submit'
            value='Submit'
            onClick={submit}
            disabled={!isChecked}>
            Submit
          </button>
        </Form>
      ) : (
        <DownloadForm>
          <h2>Download your digital card</h2>
          <p>
            Thank you for joining Cosmedic Center family. Click the button below
            to download your digital card to your phone.
          </p>

          <DownloadButtons />
          {renderWebViewMessage()}
        </DownloadForm>
      )}
      <Modal modalIsOpen={modalIsOpen} closeModal={closeModal} />
    </SectionWrapper>
  );
}

export default Form2;
