import React from "react";
import { Wrapper, Input, Label } from "./InputCustom.styles";

function InputCustom({ label, value, onChange }) {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Input onChange={(e) => onChange(e.target.value)} />
    </Wrapper>
  );
}

export default InputCustom;
