import React from 'react';
import cosmedicLogo from '../../assets/cosmedic-logo.png';
import { SectionWrapper } from './Header.styles';

function Header() {
  return (
    <SectionWrapper>
      <a href='https://cosmediccenter.qa/' target='_blank' rel='noreferrer'>
        <img src={cosmedicLogo} alt='Cosmedic Logo' />
      </a>
    </SectionWrapper>
  );
}

export default Header;
