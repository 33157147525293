import styled from "styled-components";
import { scaleHeight, scaleWidth } from "../../styles/mixins";

const SectionWrapper = styled.section`
  background-color: var(--color-footer);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${scaleWidth(64)}vw;
  height: ${scaleHeight(63)}vh;
`;

const Text = styled.a`
  font-family: var(--font-family);
  font-size: ${scaleWidth(14.4)}vw;
  text-decoration: none;
  color: var(--color-footer-text);

  @media screen and (max-width: 1280px) {
    font-size: ${scaleWidth(14.4, 1280)}vw;
  }

  @media screen and (max-width: 962px) {
    font-size: ${scaleWidth(14.4, 962)}vw;
  }

  @media screen and (max-width: 550px) {
    font-size: ${scaleWidth(14.4, 550)}vw;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;
export { SectionWrapper, Text, Bold };
