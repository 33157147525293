import axios from "axios";
import { DOMAIN } from "../config";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "en",
  },
  timeout: 10000,
  baseURL: DOMAIN,
});

export default axiosInstance;
